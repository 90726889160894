.slick-prev,
.slick-next {
  /* background-color: red !important; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  color: #fff;
  transition: 0.3s;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  @apply !bg-info;
}

.slick-slider:hover .slick-next,
.section:hover .slick-next {
  right: 25px;
  opacity: 1;
  visibility: visible;
}

.slick-slider:hover .slick-prev,
.section:hover .slick-prev {
  left: 25px;
  opacity: 1;
  visibility: visible;
}

/* .slick-prev:before,
.slick-next:before {
  color: #0a3277;
  font-family: "Font Awesome 5 Pro";
  content: "\f054";
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  text-rendering: auto;
  line-height: 1.7;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  font-size: 14px;
}

.slick-prev:before {
  content: "\f053";
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: #fff;
  background-color: #d3ad43;
}

.slick-prev:hover::before,
.slick-prev:focus::before,
.slick-next:hover::before,
.slick-next:focus::before {
  color: #fff;
} */

a:active {
  @apply !bg-info;
}

.sc-img {
  width: calc(100% / 3);
  height: auto;
  object-fit: cover;
}

/* .slick-list {
  height: 100vh !important;
} */

html {
  background-color: #fff !important;
}

.centered {
  position: absolute;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */

  transform: translate(
    -50%,
    -50%
  ); /* This is a shorthand of
                                       translateX(-50%) and translateY(-50%) */
}
